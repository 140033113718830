

@import '~@fortawesome/fontawesome-free/css/all.css';

@font-face {
  font-family: eSolutionFont;
  src: url('./fonts/inia (2).otf') format('opentype');
 

}

body, button, h1, h2, h3, h4, h5, h6, p, span, div{
  font-family: eSolutionFont !important;
}

.sidebar {
  display: flex !important;
  flex-direction: column !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

.sidebar-mid {
  background-color: transparent !important;
}

.sidebar-text {
  text-decoration: none !important;
}

* {
    box-sizing: border-box;
    padding:0;
    margin: 0;

}



.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #003976;
  color: #f1f1f1;
  font-size: 22px;
}

.navbar-logo img {
 width: 15%;
 height: 10%;
 display: none;
 
}

.navbar-menu {
  display: flex;
  align-items: center;
  font-size: 22px;
}

.navbar-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 300px;
}

.navbar-menu ul li {
  margin-right: 1rem;
  position: relative;

}

.navbar-menu ul li a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.submenu {
  position: absolute;
  top: 100%;

  right: 0;
  left: 0;
  background-color: #fff;
  /* padding: 1rem; */
  display: none;
  color : black;
  
}

.navbar-menu ul li:hover .submenu {
  display: block;
}

.submenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 80vw;
}

.submenu ul li {
  /* margin-bottom: 0.5rem; */
  display: block;
}

.navbar-search {
  cursor: pointer;
}

.navbar-toggle {
  display: none;
  cursor: pointer;
}




  .showcase {
    width: 100%;
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('images/dubai-transformed.png');
    background-size: cover;
    background-position: center;
  }
  
  .showcase-content,
  .search-container {
    flex: 1;
  }

  .showcase-content {
    background-color: #003976;
    align-self: flex-start;
    margin-top: 5% ;
    margin-right: 30%;
    margin-left: 8% ;
 
    }
  
  .showcase-content h4{
    text-align: center;
    color: white;
    font-style: italic;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .showcase-content p{
    text-align: center;
    color: white;
    margin-bottom: 30px;
  }
  .school-name {
    font-size: 2rem;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    
  }
  
  .search-container {
    border-top: 7px solid #ffa500;
    background-color: #fff;
    padding: 1% 2% 2% 1%;
    display:block;
    justify-content: center;
    align-items: center;
    margin-right: 8%;
    margin-top: 20%;
  }

  .search-container  h2 {
      font-size: 2rem;
      color:#003976;
      margin-bottom: 8px;
      padding-right: 12%;

  }

  .search-container p {
    font-size:16px;
    color:#003976;
    margin-bottom: 28px;
    padding-right: 0;

}
  
   .search-container form {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #d2d2d2;
  }
  
  input[type='text'] {
    padding: 0.5rem;
    border: none;
    border-radius: 5px 0 0 5px;
    background-color: inherit;
    width: 100%;
    font-size: 18px;
  }
  
  button[type='submit'] {
    padding: 0.7rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #042e44;
    color: #fff;
    cursor: pointer;
  }
  .course-display {
    background-color: #042e44;
    background-image: url('images/trans.png');

    background-size:auto;
    
    padding: 5%;
  }
  
  .course-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
  
  .course-card {
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-top: 4px solid red;
  }
  
  .course-name {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .course-description {
    margin-bottom: 1rem;
  }
  
  .course-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #00344c;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    border-bottom: 3px solid #280071;
    font-weight: 500;
  }
  
  .course-button:hover {
    background-color: #555;
  }
  

  .information-section {
    padding: 5%;
  }
  
  .information-content {
    text-align: left;
    justify-content: left;
  }
  
  .information-heading {
    font-size: 2rem;
    color: #003976;
    margin-bottom: 1.5rem;
  }
  
  .information-paragraph {
    font-size: 1.2rem;
    margin-top: 2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  .information-paragraph ul li {
    margin-top:20px;
  }

  .information-paragraph a {
    display: inline-block;
    color: #280071;
    text-decoration: underline;
    margin-top : 50px;
    font-weight: 700;
  }

  .grid-section {
    width: 100vw;
    height: 120vh;
    background-image: url('images/dubai-transformed.png');
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: none;
  }

.purposesection{
  background-color: #042e44 !important;
  
}

.purposesection .grid2-item {
  background-color: inherit;
  border : none;
  color: white !important;
  font-size: 40px !important;
  align-items: center  !important;

}


.purposesection .grid2-item img {
  height: 100%;
}


  .plain-bg {
    background-image: none !important;
    background-color: #32a4a8 !important;
  }

  .mettom {
    background-image: url('images/dubai-transformed.png') !important;
  }
    .container {
      width: 80%;
      margin: 0 auto;
    }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
  
  .grid-item {
  
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:inherit;
  }

  .grid-item img {
    width: 100%;
    height: 100%;

  }

  .itemone {
    padding: 10% 20% 10% 2%;
    display: flex;
   

  }
  .itemoned {
   
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;

  }

  .circle {
    align-self: flex-end;
    background-color: #005780;
    border-radius: 50%;
    padding: 30px 50px;
    margin-left:27px;
    overflow: visible;
    margin-right: -110px;
    color: #fff;
    text-align: center;
  }


  .circle h4 {
      font-size: 16px;
  }

  .circle h2{
    font-style: italic;
    font-weight: 900;
    font-size: 2.5rem;
  }
  .itemtwo {
    background-image: url('images/dubai-transformed.png');
    background-size: cover;
    background-position: center;
  }

  .meetTom {
    background-image: url('images/dubai-transformed.png');
    background-size: cover;
    background-position: center;
  }
  .itemone-card {
    background-color: #fff;
    padding:5% 10% 15% 8%;
  }

  .itemone-card h3 {
    text-align: left;
    color: #003976;
    font-size: 2rem;
    margin-right: 40%;
    margin-bottom: 10%;
  }

  .itemone-card p {
    text-align: left;
    color: #003976;
    font-size: 1.3rem;
    padding-right: 1%;
  }

  .itemone-card button {
    background-color: #ffa500;
    color: #003976;
    padding: 4px 20px;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: 800;
    border: none;
    border-bottom: 4px solid #8a5b03;
  }


  .three-grid-section {
    width: 100vw;
    /* height: 100vh; */
    background-color: #003976;
    background-image: url('images/trans.png');
    background-size: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
   
    padding: 70px 3px;
  }
  
  .grid3-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  
  .grid3-item {
    height: 100%;
    display: block;
    margin-top: 40px;

    justify-content: center;
    background-color:#fff;
    border-top: 2px solid #ffa500;
  }

  .grid3-item:hover {
    margin-top: 30px;
  }
  .grid3-item img {
      width: 100%;
      height:40%;
  }

  .grid3-item h4 {
      color: #003976;
      font-size: 1.5rem;
      margin: 30px 5px;

     
  }

  .big-icon {
    font-size: 3.5rem !important;
  }
  .grid3-item p {
    color: black;
    font-size: 1.2rem;
    margin: 10px 5px;

   
}

.grid3-item button {
  background-color: #003976;
  padding: 5px 15px;
  color: #fff;
  border: none;
  border-bottom: 4px solid #012b58;
  margin: 10px 5px;
  font-size: 1.2rem;
  font-weight: 700;
}



.two-grid-section {
  width: 100vw;
  height: auto;
  background-color: #003976;
   background-image: url('images/trans.png');
  background-size: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow:none; */

  padding: 70px 3px;
}

.grid2-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
 
 
}

.grid2-item {
  height: 100%;
  display: block;
  margin-top: 30px;

  justify-content: center;
  background-color: white;
  border-top: 2px solid #ffa500;
}

.grid2-item:hover {
  margin-top: 30px;
}
.grid2-item img {
    width: 100%;
    height:60%;
}

.grid2-item h4 {
    color: #003976;
    font-size: 1.5rem;
    margin: 20px 5px;

   
}

.grid2-item p {
  color: black;
  font-size: 1.2rem;
  margin: 10px 5px;

 
}

.grid2-item button {
background-color: #003976;
padding: 5px 15px;
color: #fff;
border: none;
border-bottom: 4px solid #012b58;
margin: 10px 5px;
font-size: 1.2rem;
font-weight: 700;
}


.flex i{

  font-size: 25px;
  margin: 5px;
}

.image-section {
  width: 100vw;
  
  color :#003976 
}


.image-section h2 {
  font-size: 2.5rem;
  margin-top: 30px;
  margin-bottom: 30px;
}
.image-container {
  position: relative;
  width: 100%;
  
  
  box-shadow: 0 0 0 gray;
  transition: box-shadow 0.3s ease;
  margin-bottom: 20px;
}

.image-container  p{
   padding-right: 15%;
   font-size: 1.5rem;
}

.image-container:hover {
  box-shadow: 0 0 10px gray;
}

.image-container img {
 
  width: 100%;
  
  
}



.next-section {
  width: 100vw;
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #003976;
}

.gridnext-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  color: white;
}

.gridnext-item {
  background-color: inherit;
  padding: 30px;
}

.inner-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
}

.inner-grid-item {
  background-color: inherit;
  padding: 10px;
}


.gridnext-item h3 {
  margin-bottom: 20px;
}

.arrow-list {
  list-style-type: none;
  padding-left: 0;
}

.arrow-list li {
  margin-bottom: 10px;
  font-size: 14px;
  text-decoration: underline;
}

.arrow-list li i {
  margin-right: 4px;
}


.footer-bg {
  background-color: #333 !important;
}




@media screen and (max-width: 1194px) {
  .navbar-menu {
    display: flex;
    align-items: center;
    font-size: 17px;
  }

  
  .showcase {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('images/dubai-transformed.png');
    background-size: cover;
    background-position: center;
  }
  
  
  .grid-section {
    width: 100vw;
    height: auto;
    background-image: url('images/dubai-transformed.png');
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: none;
  }


  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
  
  .grid-item {
  
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:inherit;
  }

  .grid-item img {
    width: 100%;
    height: 100%;

  }

  .itemone {
    padding: 10% 15% 10% 1%;
    display: flex;
   

  }
  .itemoned {
   
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;

  }

  .circle {
    align-self: flex-end;
    background-color: #005780;
    border-radius: 50%;
    padding: 30px 50px;
    margin-left:27px;
    overflow: visible;
    margin-right: -50px;
    color: #fff;
    text-align: center;
  }


  .circle h4 {
      font-size: 16px;
  }

  .circle h2{
    font-style: italic;
    font-weight: 900;
    font-size: 2.5rem;
  }
  .itemtwo {
    background-image: url('images/dubai-transformed.png');
    background-size: cover;
    background-position: center;
  }

  .meetTom {
    background-image: url('images/dubai-transformed.png');
    background-size: cover;
    background-position: center;
  }
  .itemone-card {
    background-color: #fff;
    padding:5% 10% 15% 8%;
  }

  .itemone-card h3 {
    text-align: left;
    color: #003976;
    font-size: 2rem;
    margin-right: 40%;
    margin-bottom: 10%;
  }

  .itemone-card p {
    text-align: left;
    color: #003976;
    font-size: 1.3rem;
    padding-right: 1%;
  }

  .itemone-card button {
    background-color: #ffa500;
    color: #003976;
    padding: 4px 20px;
    margin-top: 20px;
    font-size: 0.8rem;
    font-weight: 800;
    border: none;
    border-bottom: 4px solid #8a5b03;
  }


}
@media screen and (max-width: 1080px) {

.grid3-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3px;
}

.grid3-item {
  height: 100%;
  display: block;
  margin-top: 40px;

  justify-content: center;
  background-color:#fff;
  border-top: 2px solid #ffa500;
}

.grid3-item h4 {
  color: #003976;
  font-size: 1.2rem;
  margin: 30px 5px;

 
}

.grid3-item p {
color: black;
font-size: 1rem;
margin: 10px 5px;


}

.grid3-item button {
background-color: #003976;
padding: 5px 15px;
color: #fff;
border: none;
border-bottom: 4px solid #012b58;
margin: 10px 5px;
font-size: 0.8rem;
font-weight: 700;
}


}

@media screen and (max-width: 997px) {
 

  .navbar-menu {
    display: flex;
    align-items: center;
    font-size: 14px;
  }


  .showcase-content {
    background-color: #003976;
    align-self: flex-start;
    margin-top: 5% ;
    margin-right: 30%;
    margin-left: 8% ;
 
    }
  
  .showcase-content h4{
    text-align: center;
    color: white;
    font-style: italic;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .showcase-content p{
    text-align: center;
    color: white;
    margin-bottom: 30px;
    font-size: 15px;
  }
  .school-name {
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    
  }

  .search-container {
    border-top: 7px solid #ffa500;
    background-color: #fff;
    padding: 1% 2% 2% 1%;
    display:block;
    justify-content: center;
    align-items: center;
    margin-right: 8%;
    margin-top: 20%;
  }

  .search-container  h2 {
      font-size: 1rem;
      color:#003976;
      margin-bottom: 8px;
      padding-right: 12%;

  }

  .search-container p {
    font-size:12px;
    color:#003976;
    margin-bottom: 28px;
    padding-right: 0;

}
  
   .search-container form {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #d2d2d2;
  }
  
  input[type='text'] {
    padding: 0.5rem;
    border: none;
    border-radius: 5px 0 0 5px;
    background-color: inherit;
    width: 100%;
    font-size: 12px;
  }
  
  


}



@media screen and (max-width: 932px) {
  
  .grid3-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}



  
  @media screen and (max-width: 768px) {
    
  }
  
  
  @media screen and (max-width: 600px) {
   
  }
  
  @media screen and (max-width: 900px) {
    
  }
  
  @media screen and (max-width: 600px) {
    
  }
  
  
 
  

  @media screen and (max-width: 900px) {

    .navbar-logo img {
      width: 10%;
      height: 6%;
      display: block;
     }
    .navbar-menu {
      display: none;
    }
  
    .navbar-toggle {
      display: block;
    }
  
    .navbar-menu.active {
      display: block;
      background-color: #f1f1f1;
      padding: 1rem;
    }
  
    .navbar-menu.active ul {
      display: flex;
      flex-direction: column;
    }
  
    .navbar-menu.active ul li {
      margin-bottom: 0.5rem;
    }

    .course-grid {
        grid-template-columns: repeat(2, 1fr);
      }

      .grid-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
      }
  }

    
  @media screen and (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
    .grid3-container {
      grid-template-columns: 1fr;
    }
    .grid2-container {
      grid-template-columns: 1fr;
    }
    .gridnext-container {
      grid-template-columns: 1fr;
    }
    .showcase-content p{
      text-align: center;
      color: white;
      margin-bottom: 10px;
      font-size: 15px;
    }
  }
  

  @media screen and (max-width: 655px) {
    .showcase {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url('images/dubai-transformed.png');
      background-size: cover;
      background-position: center;
    }
     

    .showcase-content {
      background-color: #003976;
      align-self: center;
      margin-top: 30% ;
      margin-right: 20%;
      margin-left: 1% ;
      text-align: left;
      padding:2%;
   
      }
      .search-container {
        border-top: 7px solid #ffa500;
        background-color: #fff;
        padding: 1% 2% 2% 1%;
        display:block;
        justify-content: center;
        align-items: center;
        margin-right: 8%;
        margin-top: 2%;
      }
  }
  
  

  @media screen and (max-width: 600px) {
    .school-name {
      font-size: 2rem;
    }
  
    input[type='text'] {
      width: 100%;
    }
  
    button[type='submit'] {
      padding: 0.5rem;
    }

    .course-grid {
        grid-template-columns: 1fr;
      }

      .information-heading {
        font-size: 1.5rem;
      }
    
      .information-paragraph {
        font-size: 0.9rem;
      }
  }


  @media screen and (max-width: 320px) {
    .two-grid-section {
      padding: 10px;
    }
    
    .grid2-item {
      font-size: 12px;
    }

    .image-section {
      padding: 10px;
    }

    .next-section {
      padding: 10px;
    }
  
    .gridnext-item {
      padding: 10px;
    }
  
    .inner-grid {
      grid-template-columns: 1fr;
    }
  }


  .whychoose {
    background-color: #005fa3; 
    padding: 10px 45px ; 
    border-radius : 5px ;
    margin-bottom : 140px ;
     margin-left :   300px ;
   }

   
  @media screen and (max-width: 1129px) {
    .whychoose {
      background-color: #005fa3; 
      padding: 10px 25px ; 
      border-radius : 5px ;
      margin-bottom : 140px ;
       margin-left :   300px ;
     }
    }

      
  @media screen and (max-width: 1038px) {
    .whychoose {
      background-color: #005fa3; 
      padding: 10px 15px ; 
      border-radius : 5px ;
      margin-bottom : 140px ;
       margin-left :   100px ;
     }
    }


    @media screen and (max-width: 428px) {
      .whychoose {
        background-color: #005fa3; 
        padding: 10px 15px ; 
        border-radius : 5px ;
        margin-bottom : 140px ;
         margin-left :   10px ;
       }
      }
  
  

  @media screen and (max-width: 325px) {
  .whychoose {
    background-color: #005fa3; 
    padding: 10px 45px ; 
    border-radius : 5px ;
    margin-bottom : 140px ;
     margin-left :   300px ;
   }
  }