Link {
  text-decoration: none;
}

.academic-showcase {
    background-image: url('images/dubai-transformed.png');
    background-size:cover;
    background-position: center;
   
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 7% 2%;
  }
  


  .acadcontent h1 {
    font-size: 35px;
    font-weight: 800;
  }
 
  .acadcontent {

    flex: 1;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding: 30px;
    color:white !important;
    z-index: 1;
    opacity : 1 !important;

  }
  
  .links {
 display: block;
 background-color: black;
 padding: 2px 60px 2px 3px ;
 margin-bottom: 20px;
  }
  
  .blue {
    background-color: #003976;
    margin-right: 60%;
  }
  
  button {
    margin: 10px;
  }

  

  .feesgrid-right  h1 {
    font-size: 30px;
    font-weight: 700;
  }




  @media (max-width: 900px) {
    .academic-showcase {
      flex-direction: column;
    }
  }
  
  @media (max-width: 796px) {
    .content {
      flex-direction: column;
    }
    .blue {
        background-color: #003976;
        margin-right: 20%;
      }
  }

  @media (max-width: 420px) {
    .content {
      flex-direction: column;
    }
    .blue {
      background-color: #003976;
      margin-right: 0%;
    }
    .acadcontent button {
      font-size: 10px !important;
    }
  }
  

  /* .mini-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    z-index: 1000;
  } */
  
  .acadnavbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .acadmenu-btn {
    display: none;
  }
  
  .acadmenu {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .acadmenu li {
    margin-right: 10px;
  }
  
  .acadmenu a {
    color: #fff;
    text-decoration: none;
  }


  .time-and-duration {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    background-color: #f2f2f2;
  }
  
  .left-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
   
  }
  
  .left-div > div {
    margin-bottom: 20px;
    margin-left: 110px;
  }
  
  .center-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 796px) {
    .time-and-duration {
      flex-direction: column;
      align-items: center;
    }
  
    .left-div {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  
  @media (max-width: 420px) {
    .time-and-duration {
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
  
    .left-div {
      margin-bottom: 10px;
      text-align: center;
    }
  }
  

  .about-course {
    padding: 50px;
  }
  
  .about-course h2 {
    text-align: left;
    margin-bottom: 20px;
    color:#00344c;
    font-size: 41px;
  }
  
  .acadcoursegrid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .acadcoursegrid-div {
    padding: 20px;
    background-color: inherit;
  }
  
  .acadcoursegrid-div h6 {
    margin-bottom: 10px;
    font-size: 18px;
  }


  .about-border-left {
    background-color: #f2f2f2;
    border-left: 4px solid #00344c;
    padding: 30px;
  }
  
  .acadcoursegrid-div ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  

  .acadcoursegrid-div ul li {
    padding-top: 30px;
  }

  .entry-requirement {
    padding: 20px 200px 20px 60px;
    text-align: left;
  }
  

  .entry-requirement h2{
    text-align: left;
    margin-bottom: 20px;
    color:#00344c;
    font-size: 41px;


  }
  .entry-requirement p {
    margin-bottom: 10px;
  }
  
  .entry-requirement ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  .facility-picture {
    width: 100vw;
    height: 90vh;
    background-image: url('images/dubai-transformed.png');
   
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    
    
  }
  
  .facility-picture .facilitycontent {
  
    color: #fff;
  }
  
  .facility-picture h3 {
    background-color: #fff;
    color: #333;
    font-size: 54px;
    font-weight: 900;
     
    margin-top: 20px;
    margin-left: 20px;
    padding-right: 130px;
  }
  
  .facility-picture p {
    text-align: center;
    display: block;
    font-size: 18px;
   background-color: #333;
   padding: 0px;
  margin: 20px;
  }
  

  .facilitycontent{
    display: flex;
    flex-direction: column;
  } 
  .student-support {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    text-align: left;
    color: white;
    font-weight: 700;
    background-color: #0049af;
    padding: 20px;
  }

  .student-support h2 {
    text-align: left !important;
   
    margin-bottom: 20px;
    color: white;
    font-size: 45px;
  }


  
  .student-support p{
    font-size:20px;
  }

  .feesgrid-container {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 20% 70%;
    grid-gap: 3px;
  }
  
  .feesgrid-left {
    text-align: center;
  }
  
  .feesgrid-image {
    max-width: 50%;
    height: 50;
    border-radius: 50%;
  }
  
  .feesgrid-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .feesgrid-right h1,
  .feesgrid-right h4,
  .feesgrid-right h5,
  .feesgrid-right p {
    text-align: left;
  }
  

   .feesgrid-right h5 {
    border-top: 2px dotted black;
    padding-top: 10px;
    font-size: 18px;
   }
  .feesgrid-right h4 {
    display: block;
    margin-top: 20px;
    background-color: #0138b9;
    color: #fff;
    width: 30%;
    text-align: center;
    padding: 10px 30px;
    font-size: 20px;
  }


  .feesgrid-right p {
    font-size: 18px;
  }
  .info-section {
    text-align: left;
    margin: 40px;
    padding: 20px 180px 20px 60px;
  }


  .info-section  h2 {
    font-size: 45px;
    color :#0138b9;
  }
  
  .bullet-icon {
    margin-right: 5px;
    font-size: 248px;
    color: #0138b9;
  }
  
  @media (max-width: 768px) {
    .info-section {
      margin: 10px;
    }
  }
  
  @media (max-width: 420px) {
    .info-section {
      margin: 5px;
    }
    .acadcontent h1 {
      font-size: 20px;
      font-weight: 800;
    }
    .feesgrid-right h4 {
      display: block;
      margin-top: 20px;
      background-color: #0138b9;
      color: #fff;
      width: 100%;
      text-align: center;
      padding: 10px 30px;
      font-size: 20px;
    }
    .info-section {
      text-align: center;
      margin: 10px;
      padding: 20px 10px 20px 10px;
    }
    .info-section  h2 {
      font-size: 25px;
      color :#0138b9;
    }
    .feesgrid-right h1,
    .feesgrid-right h4,
    .feesgrid-right h5,
    .feesgrid-right p {
      text-align: center;
      padding: 10px;
    }
    .about-course h2 {
      text-align: center;
      margin-bottom: 10px;
      color:#00344c;
      font-size: 31px;
      font-weight: 800;
    }
    .about-course {
      padding: 50px;
      text-align: center;
    }
    .entry-requirement {
      padding: 20px 200px 20px 60px;
      text-align: center;
    }
    
  
    .entry-requirement h2{
      text-align: center;
      margin-bottom: 20px;
      color:#00344c;
      font-size: 21px;
  
  
    }
    .student-support {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      color: white;
      background-color: #00344c;
      padding: 20px;
      
    }
  
    .student-support h2 {
      text-align: center !important;
     
      margin-bottom: 5px;
      color: white;
      font-size: 25px;
      font-weight: 800;
    }
  
  
    
    .student-support p{
      font-size:20px;
      text-align: center;
    }
  
  }
  


  @media (max-width: 768px) {
    .feesgrid-container {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 420px) {
    .feesgrid-container {
      grid-gap: 10px;
    }
  }
  
  
  @media (min-width: 768px) {
    .student-support {
      padding: 40px;
    }
  }
  
  @media (min-width: 1024px) {
    .student-support {
      padding: 60px;
    }
  }
  
  @media (max-width: 420px) {
    .facility-picture h3 {
      font-size: 20px;
    }
  
    .facility-picture p {
      font-size: 16px;
    }
  }
  


  @media (max-width: 796px) {
    .entry-requirement {
      padding: 10px;
    }
  }
  

  @media (max-width: 420px) {
    .entry-requirement {
      padding: 10px;
    }
  
    .entry-requirement p,
    .entry-requirement ul {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 796px) {
    .acadcoursegrid-container {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 420px) {
    .about-course {
      padding: 10px;
    }
  
    .acadcoursegrid-container {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
  
  
  @media (max-width: 796px) {
    .acadmenu-btn {
      display: block;
      cursor: pointer;
    }
  
    .acadmenu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #333;
      width: 100%;
      padding: 10px;
    }
  
    .acadmenu li {
      margin: 5px 0;
    }
  
    .acadmenu a {
      color: #fff;
    }
  
    #menu-toggle:checked ~ .acadmenu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  