@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'INIA';
    src: url('util/inia.otf');
}

.sidebar {
    display: flex !important;
    flex-direction: column !important;
    text-decoration: none !important;
    background-color: transparent !important;
}

.sidebar-mid {
    background-color: transparent !important;
}

.sidebar-text {
    text-decoration: none !important;
}
