

/* src/components/LandingPage.css */
.landing-page {
  position: relative;
  height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  background-image: url('./images/priscilla-du-preez-XkKCui44iM0-unsplash.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  animation: fadeIn 2s ease-in-out;
}

.background-image3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/priscilla-du-preez-XkKCui44iM0-unsplash.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  animation: fadeIn 2s ease-in-out;
}


.background-image4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/luke-bender-oLh2uAy-kbk-unsplash.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: fadeIn 2s ease-in-out;
}

.content {
  padding: 10px;
  margin-right: 2%;
  margin-left: 50%;
  text-align: right;
  color: #fff;
  z-index: 3;
  background-color: #0049af6e;
  border-radius: 15px;
}

.title {
  font-weight: 900;
  font-size: 2.2rem;
  animation: slideUp 1s ease-in-out;
}

.description {
  font-size: 1.2rem;
  padding-left: 80px;
  margin-top: 20px;
  animation: slideUp 1s ease-in-out 0.5s;
}

.cta-button {
  background-color: #032e44;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  animation: slideUp 1s ease-in-out 1s;
}

.cta-button:hover {
  background-color: #005fa3;
}

@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* second landingpage2 */

/* src/components/LandingPage2.css */
.landing-page2 {
  position: relative;
  height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.background-image2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/tourism.avif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: fadeIn2 2s ease-in-out;
}


.background-image5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/dubai-transformed.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: fadeIn2 2s ease-in-out;
}
.content2 {
  padding: 20px;
  margin-right: 60%;
  margin-left: 2%;
  text-align: left;
  color: black;
  font-weight: 900;
  z-index: 3;
  background-color: white;
  border-radius: 10px;
 border-bottom: 3px solid black;
 border-left: 3px solid #0000003d;
}

.title2 {
  font-weight: 900;
  font-size: 2.5rem;
  animation: slideUp2 1s ease-in-out;
}

.description2 {
  font-size: 1.2rem;
  margin-top: 20px;
  animation: slideUp2 1s ease-in-out 0.5s;
}

.cta-button2 {
  background-color: #032e44;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  animation: slideUp2 1s ease-in-out 1s;
}

.cta-button2:hover {
  background-color: #005fa3;
}

@keyframes slideUp2 {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* src/components/LandingPage.css */
/* ... Existing styles ... */

@media (max-width: 1181px) {
  .landing-page {
    position: relative;
    height: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing-page2 {
    position: relative;
    height: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  
  .title {
    font-weight: 900;
    font-size: 1.7rem;
    animation: slideUp 1s ease-in-out;
  }
  
  .description {
    font-size: 1rem;
    margin-top: 20px;
    animation: slideUp 1s ease-in-out 0.5s;
  }
  
  .cta-button {
    background-color: #032e44;
    border-radius: 10px;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    animation: slideUp 1s ease-in-out 1s;
  }

  
.title2 {
  font-weight: 900;
  font-size: 2rem;
  animation: slideUp2 1s ease-in-out;
}

.description2 {
  font-size: 1rem;
  margin-top: 20px;
  animation: slideUp2 1s ease-in-out 0.5s;
}

.cta-button2 {
  background-color: #032e44;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 10px 10px;
  font-size: 0.8rem;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  animation: slideUp2 1s ease-in-out 1s;
}

  
}

@media (max-width: 940px) {

  .content {
    margin-right: 1%;
    margin-left: 55%;
    text-align: right;
    color: #fff;
    z-index: 2;
  }
  
  .title {
    font-weight: 900;
    font-size: 1.3rem;
    animation: slideUp 1s ease-in-out;
  }
  
  .description {
    font-size: 0.8rem;
    margin-top: 20px;
    animation: slideUp 1s ease-in-out 0.5s;
  }
  
  .cta-button {
    background-color: #032e44;
    border-radius: 10px;
    color: #fff;
    border: none;
    padding: 10px 10px;
    font-size: 0.8rem;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    animation: slideUp 1s ease-in-out 1s;
  }

  
  .content2 {
    padding: 10px;
    margin-right: 60%;
    margin-left: 1%;
    text-align: left;
    color: black;
    font-weight: 900;
    z-index: 3;
    background-color: white;
    border-radius: 10px;
   border-bottom: 3px solid black;
   border-left: 3px solid #0000003d;
  }

.title2 {
  font-weight: 900;
  font-size: 1.3rem;
  animation: slideUp2 1s ease-in-out;
}

.description2 {
  font-size: 0.8rem;
  margin-top: 20px;
  animation: slideUp2 1s ease-in-out 0.5s;
}

.cta-button2 {
  background-color: #032e44;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  animation: slideUp2 1s ease-in-out 1s;
}

  
}

@media (max-width: 799px) {
  .content {
    margin-right: 1%;
    margin-left: 42%;
    text-align: right;
    color: #fff;
    z-index: 2;
    padding: 20px;
  }
   
  .content2 {
    padding: 20px;
    margin-right: 40%;
    margin-left: 2%;
    text-align: left;
    color: black;
    font-weight: 900;
    z-index: 3;
    background-color: white;
    border-radius: 10px;
   border-bottom: 3px solid black;
   border-left: 3px solid #0000003d;
  }
  .title2 {
    font-weight: 900;
    font-size: 1rem;
    animation: slideUp2 1s ease-in-out;
  }
  
  .description2 {
    font-size: 0.6rem;
    margin-top: 20px;
    animation: slideUp2 1s ease-in-out 0.5s;
  }
  
  .cta-button2 {
    background-color: #032e44;
    border-radius: 10px;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 0.8rem;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    animation: slideUp2 1s ease-in-out 1s;
  }


  

}


@media (max-width: 500px) {
  .landing-page {
    position: relative;
    height: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing-page2 {
    position: relative;
    height: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

  .content {
    margin-right: 2%;
    margin-left: 2%;
    text-align: right;
    color: #fff;
    z-index: 2;
    padding: 20px;
    padding-top: 100px;
  }
   
  .content2 {margin-top: 100px;
    padding: 10px;
    margin-right: 32%;
    margin-left: 2%;
    text-align: left;
    color: black;
    font-weight: 900;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.459);
    border-radius: 10px;
   border-bottom: 3px solid black;
   border-left: 3px solid #0000003d;
  }
  .title2 {
    font-weight: 900;
    font-size: 1rem;
    animation: slideUp2 1s ease-in-out;
  }
  
  .description2 {
    font-size: 0.6rem;
    margin-top: 20px;
    animation: slideUp2 1s ease-in-out 0.5s;
  }
  
  .cta-button2 {
    background-color: #032e44;
    border-radius: 10px;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 0.8rem;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    animation: slideUp2 1s ease-in-out 1s;
  }
 
  .title {
    font-weight: 900;
    font-size: 1rem;
    animation: slideUp 1s ease-in-out;
  }
  
  .description {
    font-size: 0.6rem;
    margin-top: 20px;
    animation: slideUp 1s ease-in-out 0.5s;
  }
  
  .cta-button {
    background-color: #032e44;
    border-radius: 10px;
    color: #fff;
    border: none;
    padding: 10px 10px;
    font-size: 0.8rem;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    animation: slideUp 1s ease-in-out 1s;
  }


}

@media (max-width: 350px) {
  .landing-page {
    position: relative;
    height: 250px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing-page2 {
    position: relative;
    height: 250px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    margin-right: 2%;
    margin-left: 2%;
    text-align: right;
    color: #fff;
    z-index: 2;
    padding: 20px;
    padding-top: 100px;
  }
   
  .content2 {margin-top: 100px;
    padding: 10px;
    margin-right: 32%;
    margin-left: 2%;
    text-align: left;
    color: black;
    font-weight: 900;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.459);
    border-radius: 10px;
   border-bottom: 3px solid black;
   border-left: 3px solid #0000003d;
  }
  .title2 {
    font-weight: 900;
    font-size: 0.8rem;
    animation: slideUp2 1s ease-in-out;
  }
  
  .description2 {
    font-size: 0.5rem;
    margin-top: 10px;
    animation: slideUp2 1s ease-in-out 0.5s;
  }
  
  .cta-button2 {
    background-color: #032e44;
    border-radius: 10px;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 0.8rem;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    animation: slideUp2 1s ease-in-out 1s;
  }
 
  .title {
    font-weight: 900;
    font-size: 0.8rem;
    animation: slideUp 1s ease-in-out;
  }
  
  .description {
    font-size: 0.5rem;
    margin-top: 20px;
    animation: slideUp 1s ease-in-out 0.5s;
  }
  
  .cta-button {
    background-color: #032e44;
    border-radius: 10px;
    color: #fff;
    border: none;
    padding: 10px 10px;
    font-size: 0.8rem;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    animation: slideUp 1s ease-in-out 1s;
  }

  
 
}





/* src/components/GridSection.css */
.textCentr{
  background-color: #0049af;
}

.textCentr h1 {
  padding-top: 40px;
  color: white;
  text-align: center;
  font-weight: 900;
}

.icongrid-section {
  background-color: #0049af;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: 0 auto;
  gap: 10px;
  padding: 100px 20px;
  text-align: center;
  animation: slideInSection 0.5s ease-in-out 2s forwards;
}

.icongrid-item {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Initial translation for slide-in effect */
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.seticon {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 10px;
  padding: 10px;
  color:    #024876;
}


/* .icon {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 10px;
  padding: 10px;
  color:    #024876;
} */
.text {
  font-size: 1rem;
  font-weight: 900;
}

@keyframes slideInSection {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Individual animations for each grid item with delays */
.icongrid-item:nth-child(1) {
  animation-name: slideInItem1;
  animation-delay: 2.5s;
}

.icongrid-item:nth-child(2) {
  animation-name: slideInItem2;
  animation-delay: 3s;
}

.icongrid-item:nth-child(3) {
  animation-name: slideInItem3;
  animation-delay: 3.5s;
}

.icongrid-item:nth-child(4) {
  animation-name: slideInItem4;
  animation-delay: 4s;
}

.icongrid-item:nth-child(5) {
  animation-name: slideInItem5;
  animation-delay: 4.5s;
}

.icongrid-item:nth-child(6) {
  animation-name: slideInItem6;
  animation-delay: 5s;
}

@keyframes slideInItem1 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInItem2 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInItem3 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInItem4 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInItem5 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInItem6 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* src/components/SimpleSection.css */
.simple-section {
  background-color: #0049af;
  text-align: center;
  padding: 40px 0;
  opacity: 0; /* Initially hidden */
  transition: opacity 2.5s ease-in-out;
}

.navy-blue-text {
  color: white;
  font-weight: 900;
  /* font-size: 1rem; */
}

.center-aligned {
 font-size: 1.3rem;
 color: white;
  
  margin: 20px 270px;
}


@media (max-width: 988px) {
.simple-section {
  background-color: #005fa3;
  text-align: center;
  padding: 10px 0;
  opacity: 0; /* Initially hidden */
  transition: opacity 2.5s ease-in-out;
}

.center-aligned {
  font-size: 1.3rem;
  color: white;
   
   margin: 20px 10px;
 }
}
/* Adjust styles for smaller screens */
@media (max-width: 768px) {
  .center-aligned {
    font-size: 1rem;
  }
}

.fade-in {
  opacity: 1; /* Fades in when the 'fade-in' class is added */
}

/* src/components/VisaTypesSection.css */
.visa-types-section {
  background-color: #0049af;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 30px 20px;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease-in-out;
}

.visa-type {
  background-color: #f8f8f8;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0; /* Initially hidden */
  transform: translateX(-20px); /* Initial translation for slide-in effect */
  animation-duration: 2.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.visa-type img {
  border-radius: 5px;
  width: 100%;
  height: 50%;
  display: block;
  margin: 0 auto;
}

.visa-type h3 {
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

.visa-type p {
  text-align: center;
  padding: 4px;
  font-size: 0.7rem;
}

.contact-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 12px;
  margin-left: 20px;
  text-decoration: none;
  background-color: #0049af;
  color: #fff;
  padding: 10px 20px;
  font-size: 0.6rem;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.contact-button span {
  margin-left: 5px;
}

.contact-button:hover {
  background-color: #005fa3;
}

/* Individual animations for each visa-type with delays */
.visa-type:nth-child(1) {
  animation-name: slideInVisa1;
  animation-delay: 2.5s;
}

.visa-type:nth-child(2) {
  animation-name: slideInVisa2;
  animation-delay: 3s;
}

.visa-type:nth-child(3) {
  animation-name: slideInVisa3;
  animation-delay: 3.5s;
}

.visa-type:nth-child(4) {
  animation-name: slideInVisa4;
  animation-delay: 4s;
}

.visa-type:nth-child(5) {
  animation-name: slideInVisa5;
  animation-delay: 4.5s;
}

@keyframes slideInVisa1 {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInVisa2 {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInVisa3 {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInVisa4 {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInVisa5 {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.visafade-in {
  opacity: 1; /* Fades in when the 'fade-in' class is added */
}



@media (max-width: 1210px) {
  .visa-types-section {
    background-color: #005fa3;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 5px;
    padding: 30px 10px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease-in-out;
  }
  
  .visa-type {
    background-color: #f8f8f8;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    opacity: 0; /* Initially hidden */
    transform: translateX(-20px); /* Initial translation for slide-in effect */
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  
  .visa-type img {
    border-radius: 5px;
    width: 100%;
    height: 40%;
    display: block;
    margin: 0 auto;
  }
  
  .visa-type h3 {
    font-size: 0.8rem;
    margin-top: 10px;
    text-align: center;
  }
  
  .visa-type p {
    text-align: center;
    padding: 4px;
    font-size: 0.6rem;
  }
  
  .contact-button {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 12px;
    margin-left: 20px;
    text-decoration: none;
    background-color: #007acc;
    color: #fff;
    padding: 10px 20px;
    font-size: 0.5rem;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .contact-button span {
    margin-left: 5px;
  }
  
  .visafade-in {
    opacity: 1; /* Fades in when the 'fade-in' class is added */
  }
}


@media (max-width: 1014px) {
  .visa-types-section {
    background-color: #005fa3;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 25px;
    padding: 30px 10px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease-in-out;
  }


  .visa-type img {
    border-radius: 5px;
    width: 100%;
    height: 50%;
    display: block;
    margin: 0 auto;
  }
  .visafade-in {
    opacity: 1; /* Fades in when the 'fade-in' class is added */
  }
}
/* Adjust styles for smaller screens */
@media (max-width: 768px) {
   

  .visa-types-section {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Adjust grid columns for smaller screens */
  }

  .visa-type {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .visa-type h3 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .visa-type p {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  .contact-button {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 498px) {
   

  .visa-types-section {
    grid-template-columns: 1fr; /* Adjust grid columns for smaller screens */
  }

  .visa-type {
    padding: 5px; /* Adjust padding for smaller screens */
  }

  .visa-type h3 {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
  }

  .visa-type p {
    font-size: 0.6rem; /* Adjust font size for smaller screens */
  }

  .contact-button {
    font-size: 0.6rem; /* Adjust font size for smaller screens */
  }
}


/* src/components/UniqueServicesSection.css */
/* src/components/UniqueServicesSection.css */
.unique-services-section {
  background-color: black;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 40px 20px;
}

.unique-service {
  border: 1px solid #0049af;
  padding: 25px 50px;
  text-align: center;
  color: #fff;
  transform: translateY(20px); /* Initial translation for flip-in effect */
  opacity: 0; /* Initially hidden */
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

 .serviceicon {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #f8f8f8;
}

h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

p {
  font-size: 1rem;
}

/* Add flip-in animation */
.flip-in {
  transform: translateY(0);
  opacity: 1;
}

/* Add transition delays for the flip-in effect */
.unique-service:nth-child(1) {
  transition-delay: 0.2s;
}

.unique-service:nth-child(2) {
  transition-delay: 0.4s;
}

.unique-service:nth-child(3) {
  transition-delay: 0.6s;
}

.unique-service:nth-child(4) {
  transition-delay: 0.8s;
}

.unique-service:nth-child(5) {
  transition-delay: 1s;
}

.unique-service:nth-child(6) {
  transition-delay: 1.2s;
}

/* Adjust styles for smaller screens */
@media (max-width: 796px) {
  .unique-services-section {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    gap: 10px; /* Adjust gap for smaller screens */
  }

  .icon {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }

  h3 {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  p {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}
@media (max-width: 661px) {
  .unique-services-section {
    grid-template-columns: repeat(1, 1fr); /* 2 columns on smaller screens */
    gap: 10px; /* Adjust gap for smaller screens */
  }

  .icon {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }

  h3 {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  p {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }


  .testimonial-section {
    background-color: #001f3f;
    color: #fff;
    padding: 60px 0;
    opacity: 1; /* Initially hidden */
    transition: opacity 0.5s ease-in-out;
  }
  
  .testimonial-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .testimonial-image img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }
  
  .testimonial-text {
    flex: 1;
    padding-left: 20px;
    text-align: left;
  }
  
  h3 {
    font-size: 1rem;
    margin-bottom: 5px;
    font-weight: 900;
  }
  
  h2 {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .rating {
    font-size: 1.1rem;
  }
  
  .star-icon {
    color: #fff;
  }
  
  p {
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
  
  .quote-icon {
    font-size: 1.5rem;
    text-align: right;
    color: #fff;
  }
  
  /* Animation */
  .testifade-in {
    opacity: 1;
  }
  

  
}




/* Further adjust styles for screens narrower than 340px */
@media (max-width: 340px) {
  .unique-services-section {
    grid-template-columns: 1fr; /* 1 column on very small screens */
  }
}


/* src/components/TestimonialSection.css */
.testimonial-section {
  background-color: #001f3f;
  color: #fff;
  padding: 60px 0;
  opacity: 1; /* Initially hidden */
  transition: opacity 0.5s ease-in-out;
}

.testimonial-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.testimonial-image img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.testimonial-text {
  flex: 1;
  padding-left: 20px;
  text-align: left;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  font-weight: 900;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.rating {
  font-size: 1.5rem;
}

.star-icon {
  color: #fff;
}

p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.quote-icon {
  font-size: 2rem;
  text-align: right;
  color: #fff;
}

/* Animation */
.testifade-in {
  opacity: 1;
}


/* src/components/BeautifulStep.css */
.beautiful-step {

  background-color: #0049af;
  text-align: center;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Initial translation for slide-in effect */
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.step-row1 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 210px;
}

.step-row2 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 330px;
}

.step-row3 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 500px;
}

.step-row4 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 580px;
}


.step-row5 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 650px;
}

.step-button {
  background-color: black;
  color: white;
  font-weight: 900;
  border: none;
  padding: 20px 100px;
  border-radius: 5px;
  font-size: 1rem;
  margin: 5px 5px;
  opacity: 0; /* Initially hidden */
  transform: translateX(-100px); /* Initial translation for slide-in effect */
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
}

.step-button:hover {
  margin-left: 7px;
}

.arrow-icon {
  font-size: 2rem;
  color: #fff;
  opacity: 1; /* Initially hidden */
  transform: scale(0.8); /* Initial scale for the arrow icon */
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
}

/* Animation */
.stepfade-in {
  opacity: 1;
  transform: translateY(0);
}

.step-button.stepslide-in {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
}

.arrow-icon.stepslide-in {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
}




@media (max-width: 1205px) {
.beautiful-step {

  background-color: #005fa3;
  text-align: center;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Initial translation for slide-in effect */
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.step-row1 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 160px;
}

.step-row2 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 280px;
}

.step-row3 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 450px;
}

.step-row4 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 530px;
}


.step-row5 {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 600px;
}

.step-button {
  background-color: #001f3f;
  color: #fff;
  font-weight: 900;
  border: none;
  padding: 20px 100px;
  border-radius: 5px;
  font-size: 1rem;
  margin: 5px 5px;
  opacity: 0; /* Initially hidden */
  transform: translateX(-100px); /* Initial translation for slide-in effect */
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
}

.step-button:hover {
  margin-left: 7px;
}

.arrow-icon {
  font-size: 2rem;
  color: #fff;
  opacity: 1; /* Initially hidden */
  transform: scale(0.8); /* Initial scale for the arrow icon */
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
}
.stepfade-in {
  opacity: 1;
  transform: translateY(0);
}

.step-button.stepslide-in {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
}

.arrow-icon.stepslide-in {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
}
}

@media (max-width: 1057px) {
  .beautiful-step {
  
    background-color: #005fa3;
    text-align: center;
    opacity: 0; /* Initially hidden */
    transform: translateY(20px); /* Initial translation for slide-in effect */
    transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 40px;
  }
  
  .step-row1 {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 110px;
  }
  
  .step-row2 {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 230px;
  }
  
  .step-row3 {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 400px;
  }
  
  .step-row4 {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 480px;
  }
  
  
  .step-row5 {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 550px;
  }
  
  .step-button {
    background-color: #001f3f;
    color: #fff;
    font-weight: 900;
    border: none;
    padding: 20px 100px;
    border-radius: 5px;
    font-size: 0.8rem;
    margin: 5px 5px;
    opacity: 0; /* Initially hidden */
    transform: translateX(-100px); /* Initial translation for slide-in effect */
    transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
  }
  
  .step-button:hover {
    margin-left: 7px;
  }
  
  .arrow-icon {
    font-size: 2rem;
    color: #fff;
    opacity: 1; /* Initially hidden */
    transform: scale(0.8); /* Initial scale for the arrow icon */
    transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
  }
  .stepfade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .step-button.stepslide-in {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
  }
  
  .arrow-icon.stepslide-in {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
  }
  }


  @media (max-width: 961px) {
    .beautiful-step {
    
      background-color: #005fa3;
      text-align: center;
      opacity: 0; /* Initially hidden */
      transform: translateY(20px); /* Initial translation for slide-in effect */
      transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
      margin-top: 0px;
      padding-top: 10px;
      padding-bottom: 40px;
    }
    
    .step-row1 {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 60px;
    }
    
    .step-row2 {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 180px;
    }
    
    .step-row3 {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 350px;
    }
    
    .step-row4 {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 430px;
    }
    
    
    .step-row5 {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 500px;
    }
    
    .step-button {
      background-color: #001f3f;
      color: #fff;
      font-weight: 900;
      border: none;
      padding: 20px 80px;
      border-radius: 5px;
      font-size: 0.8rem;
      margin: 5px 5px;
      opacity: 0; /* Initially hidden */
      transform: translateX(-100px); /* Initial translation for slide-in effect */
      transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
    }
    
    .step-button:hover {
      margin-left: 7px;
    }
    
    .arrow-icon {
      font-size: 2rem;
      color: #fff;
      opacity: 1; /* Initially hidden */
      transform: scale(0.8); /* Initial scale for the arrow icon */
      transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
    }
    .stepfade-in {
      opacity: 1;
      transform: translateY(0);
    }
    
    .step-button.stepslide-in {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
    }
    
    .arrow-icon.stepslide-in {
      opacity: 1;
      transform: scale(1);
      transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
    }
    }

    @media (max-width: 865px) {
      .beautiful-step {
      
        background-color: #005fa3;
        text-align: center;
        opacity: 0; /* Initially hidden */
        transform: translateY(20px); /* Initial translation for slide-in effect */
        transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
        margin-top: 0px;
        padding-top: 10px;
        padding-bottom: 40px;
      }
      
      .step-row1 {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 10px;
      }
      
      .step-row2 {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 130px;
      }
      
      .step-row3 {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 300px;
      }
      
      .step-row4 {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 380px;
      }
      
      
      .step-row5 {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 450px;
      }
      
      .step-button {
        background-color: #001f3f;
        color: #fff;
        font-weight: 900;
        border: none;
        padding: 20px 60px;
        border-radius: 5px;
        font-size: 0.6rem;
        margin: 5px 5px;
        opacity: 0; /* Initially hidden */
        transform: translateX(-100px); /* Initial translation for slide-in effect */
        transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
      }
      
      .step-button:hover {
        margin-left: 7px;
      }
      
      .arrow-icon {
        font-size: 2rem;
        color: #fff;
        opacity: 1; /* Initially hidden */
        transform: scale(0.8); /* Initial scale for the arrow icon */
        transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
      }
      .stepfade-in {
        opacity: 1;
        transform: translateY(0);
      }
      
      .step-button.stepslide-in {
        opacity: 1;
        transform: translateX(0);
        transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
      }
      
      .arrow-icon.stepslide-in {
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
      }
      }

      @media (max-width: 741px) {
        .beautiful-step {
        
          background-color: #005fa3;
          text-align: center;
          opacity: 0; /* Initially hidden */
          transform: translateY(20px); /* Initial translation for slide-in effect */
          transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
          margin-top: 0px;
          padding-top: 10px;
          padding-bottom: 40px;
        }
        
        .step-row1 {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-left: 10px;
        }
        
        .step-row2 {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-left: 90px;
        }
        
        .step-row3 {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-left: 200px;
        }
        
        .step-row4 {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-left: 270px;
        }
        
        
        .step-row5 {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-left: 350px;
        }
        
        .step-button {
          background-color: #001f3f;
          color: #fff;
          font-weight: 900;
          border: none;
          padding: 20px 60px;
          border-radius: 5px;
          font-size: 0.6rem;
          margin: 5px 5px;
          opacity: 0; /* Initially hidden */
          transform: translateX(-100px); /* Initial translation for slide-in effect */
          transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
        }
        
        .step-button:hover {
          margin-left: 7px;
        }
        
        .arrow-icon {
          font-size: 2rem;
          color: #fff;
          opacity: 1; /* Initially hidden */
          transform: scale(0.8); /* Initial scale for the arrow icon */
          transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
        }
        .stepfade-in {
          opacity: 1;
          transform: translateY(0);
        }
        
        .step-button.stepslide-in {
          opacity: 1;
          transform: translateX(0);
          transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
        }
        
        .arrow-icon.stepslide-in {
          opacity: 1;
          transform: scale(1);
          transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
        }
        }

        @media (max-width: 645px) {
          .beautiful-step {
          
            background-color: #005fa3;
            text-align: center;
            opacity: 0; /* Initially hidden */
            transform: translateY(20px); /* Initial translation for slide-in effect */
            transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
            margin-top: 0px;
            padding-top: 10px;
            padding-bottom: 40px;
          }
          
          .step-row1 {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-left: 10px;
          }
          
          .step-row2 {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-left: 60px;
          }
          
          .step-row3 {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-left: 180px;
          }
          
          .step-row4 {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-left: 240px;
          }
          
          
          .step-row5 {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-left: 290px;
          }
          
          .step-button {
            background-color: #001f3f;
            color: #fff;
            font-weight: 900;
            border: none;
            padding: 20px 60px;
            border-radius: 5px;
            font-size: 0.6rem;
            margin: 5px 5px;
            opacity: 0; /* Initially hidden */
            transform: translateX(-100px); /* Initial translation for slide-in effect */
            transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
          }
          
          .step-button:hover {
            margin-left: 7px;
          }
          
          .arrow-icon {
            font-size: 2rem;
            color: #fff;
            opacity: 1; /* Initially hidden */
            transform: scale(0.8); /* Initial scale for the arrow icon */
            transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
          }
          .stepfade-in {
            opacity: 1;
            transform: translateY(0);
          }
          
          .step-button.stepslide-in {
            opacity: 1;
            transform: translateX(0);
            transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
          }
          
          .arrow-icon.stepslide-in {
            opacity: 1;
            transform: scale(1);
            transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
          }
          }

          @media (max-width: 569px) {
            .beautiful-step {
            
              background-color: #005fa3;
              text-align: center;
              opacity: 0; /* Initially hidden */
              transform: translateY(20px); /* Initial translation for slide-in effect */
              transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
              margin-top: 0px;
              padding-top: 10px;
              padding-bottom: 40px;
            }
            
            .step-row1 {
              display: flex;
              justify-content: start;
              align-items: center;
              margin-left: 10px;
            }
            
            .step-row2 {
              display: flex;
              justify-content: start;
              align-items: center;
              margin-left: 60px;
            }
            
            .step-row3 {
              display: flex;
              justify-content: start;
              align-items: center;
              margin-left: 150px;
            }
            
            .step-row4 {
              display: flex;
              justify-content: start;
              align-items: center;
              margin-left: 200px;
            }
            
            
            .step-row5 {
              display: flex;
              justify-content: start;
              align-items: center;
              margin-left: 250px;
            }
            
            .step-button {
              background-color: #001f3f;
              color: #fff;
              font-weight: 900;
              border: none;
              padding: 20px 30px;
              border-radius: 5px;
              font-size: 0.6rem;
              margin: 5px 5px;
              opacity: 0; /* Initially hidden */
              transform: translateX(-100px); /* Initial translation for slide-in effect */
              transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
            }
            
            .step-button:hover {
              margin-left: 7px;
            }
            
            .arrow-icon {
              font-size: 2rem;
              color: #fff;
              opacity: 1; /* Initially hidden */
              transform: scale(0.8); /* Initial scale for the arrow icon */
              transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
            }
            .stepfade-in {
              opacity: 1;
              transform: translateY(0);
            }
            
            .step-button.stepslide-in {
              opacity: 1;
              transform: translateX(0);
              transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
            }
            
            .arrow-icon.stepslide-in {
              opacity: 1;
              transform: scale(1);
              transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
            }
            }

            @media (max-width: 477px) {
              .beautiful-step {
              
                background-color: #005fa3;
                text-align: center;
                opacity: 0; /* Initially hidden */
                transform: translateY(20px); /* Initial translation for slide-in effect */
                transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
                margin-top: 0px;
                padding-top: 10px;
                padding-bottom: 40px;
              }
              
              .step-row1 {
                display: flex;
                justify-content: start;
                align-items: center;
                margin-left: 3px;
              }
              
              .step-row2 {
                display: flex;
                justify-content: start;
                align-items: center;
                margin-left: 40px;
              }
              
              .step-row3 {
                display: flex;
                justify-content: start;
                align-items: center;
                margin-left: 120px;
              }
              
              .step-row4 {
                display: flex;
                justify-content: start;
                align-items: center;
                margin-left: 150px;
              }
              
              
              .step-row5 {
                display: flex;
                justify-content: start;
                align-items: center;
                margin-left: 200px;
              }
              
              .step-button {
                background-color: #001f3f;
                color: #fff;
                font-weight: 900;
                border: none;
                padding: 20px 30px;
                border-radius: 5px;
                font-size: 0.6rem;
                margin: 5px 5px;
                opacity: 0; /* Initially hidden */
                transform: translateX(-100px); /* Initial translation for slide-in effect */
                transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
              }
              
              .step-button:hover {
                margin-left: 7px;
              }
              
              .arrow-icon {
                font-size: 2rem;
                color: #fff;
                opacity: 1; /* Initially hidden */
                transform: scale(0.8); /* Initial scale for the arrow icon */
                transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
              }
              .stepfade-in {
                opacity: 1;
                transform: translateY(0);
              }
              
              .step-button.stepslide-in {
                opacity: 1;
                transform: translateX(0);
                transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
              }
              
              .arrow-icon.stepslide-in {
                opacity: 1;
                transform: scale(1);
                transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
              }
              }

              @media (max-width: 421px) {
                .beautiful-step {
                
                  background-color: #005fa3;
                  text-align: center;
                  opacity: 0; /* Initially hidden */
                  transform: translateY(20px); /* Initial translation for slide-in effect */
                  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
                  margin-top: 0px;
                  padding-top: 10px;
                  padding-bottom: 40px;
                }
                
                .step-row1 {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  margin-left: 3px;
                }
                
                .step-row2 {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  margin-left: 40px;
                }
                
                .step-row3 {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  margin-left: 120px;
                }
                
                .step-row4 {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  margin-left: 150px;
                }
                
                
                .step-row5 {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  margin-left: 200px;
                }
                
                .step-button {
                  background-color: #001f3f;
                  color: #fff;
                  font-weight: 900;
                  border: none;
                  padding: 10px 10px;
                  border-radius: 5px;
                  font-size: 0.5rem;
                  margin: 5px 5px;
                  opacity: 0; /* Initially hidden */
                  transform: translateX(-100px); /* Initial translation for slide-in effect */
                  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
                }
                
                .step-button:hover {
                  margin-left: 7px;
                }
                
                .arrow-icon {
                  font-size: 2rem;
                  color: #fff;
                  opacity: 1; /* Initially hidden */
                  transform: scale(0.8); /* Initial scale for the arrow icon */
                  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
                }
                .stepfade-in {
                  opacity: 1;
                  transform: translateY(0);
                }
                
                .step-button.stepslide-in {
                  opacity: 1;
                  transform: translateX(0);
                  transition-delay: 0.2s; /* Delay the slide-in animation for buttons */
                }
                
                .arrow-icon.stepslide-in {
                  opacity: 1;
                  transform: scale(1);
                  transition-delay: 0.2s; /* Delay the slide-in animation for arrow icon */
                }
                }

              