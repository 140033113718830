
@import '~@fortawesome/fontawesome-free/css/all.css';

@font-face {
  font-family: eSolutionFont;
  src: url('./fonts/inia (2).otf') format('opentype');
 

}

body, button, h1, h2, h3, h4, h5, h6, p, span, div{
  font-family: eSolutionFont !important;
}




* {
    box-sizing: border-box;
    padding:0;
    margin: 0;

}


a{
    color:inherit;
}
 

.forcedh1 {
  font-size: 35px;
  font-weight: 900;
}

Link {
  text-decoration: none;
}

.pre-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0049af;
    color: white;
    padding: 10px 40px;
    width: 100vw;
  
  }
  
  .left-section {
    display: flex;
    flex-direction: row;
  }

  .left-section  span  {
    padding-left: 15px;
    font-weight: 700;
  }
  
  .right-section {
    display: flex;
    margin-left: 10px;
  }
  
  .right-section a {
    color: white;
    margin-left: 10px;
    text-decoration: none;
  }
  

  .newnavbar {
    background-color: white;
    padding: 2px 40px;
    color: red;
    width: 100vw;
  
  
 
  }
  
  .newnavbar-container {
    width: 100%;
  
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
  }
  
.newnavbar-left {
  margin: 0px;
  width: 400px;
   height: 70px;
}


  .logo {
    width: 100%;
    max-height: 90%;
    padding-right: 190px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    
  }
  
  .newnavbar-middle {
    display: flex;
    gap: 30px;
    flex: 1;
    font-size: 15px;
    font-weight: 800;
    transition: all 0.3s ease;
    overflow: hidden;
    color: black;
  }
  
  .newnavbar-middle.open {
    font-size: 12px;
    gap: 10px;
  }
  
  .newnavbar-middle a {
    color: black;
    text-decoration: none;
  }
  
  .newnavbar-middle a:hover {
    color :#bb0b0b;
  }
  .newnavbar-right {
    display: flex;
  }
  
  .btn {
    background-color: #042e44;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 800;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger span {
    width: 25px;
    height: 3px;
    background-color: black;
    margin-bottom: 5px;
  }
  


  .newshowcase {
    
    background-image: url('images/dubai-transformed.png');
    background-size:cover;
    background-position: center;
    height: 80vh;
    max-width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content:flex-start;
    color: white;
   
  }
  
  .newshowcase-content {
    text-align: left;
    background-color: inherit;
    margin-top: 10%;
    margin-left: 140px;
    
  }
  
  .newshowcase-content h1 {
    font-size: 46px;
    font-weight: 700;
    margin-right: 20%;
  }
  
  .newshowcase-content h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .newnext-section {
    background-color: #fff;
    padding: 20px;
  }
  
  .newgrid-container {
    margin: 20px auto;
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 20px;
    justify-items: center;
  }
  
  .newgrid-item {
    
    padding: 20px;
    text-align: left;
    flex-direction: column;
  }
  
  .newgrid-item h5 {
    font-size: 24px;
    display: block;
    text-align: left;
    margin-top : 20px;
    color: #bb0b0b;
    font-weight: 500;
    /* font-size: 18px; */
  }
  
  .newgrid-item h2 {
    font-size: 36px;
    font-weight: 700;
    text-align: left;
  }
  
  .red-button {
    background-color: #042e44;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 70px ;
    font-size: 20px;

  }


  .sectionfour-bg {
    padding: 5% 5% 1% 5%;
    /* background: url('./images/dot_bg.png'); */
   
  
  }
  .grid-parent-two {
    display: grid;
    grid-template-columns:  repeat(2, 1fr);
    gap:40px;
    align-items: center;
  }
  .grid-child-50percent h3 {
       font-size: 2.1rem;
       letter-spacing: 1.8px;
       padding-right: 10px;
       color: #280071;
       font-weight: 700;
  
  }
  
 
  
  .grid-child-50percent  p {
    margin-top: 30px;
    font-size: 1rem;
    color:gray
  
  }
  .straight-grid {
    margin-top: 30px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 30px;
  }
  .straight-grid h4 {
    color:#280071;
  }
  
  .flex-20-80 {
       display : flex;
       align-items: center;
       justify-content: space-around;
  }
  .flex-20 {
    width : 20%;
  }
  
  .flex-80 {
    width: 80%;
  
  }
  .flex-80 h4  {
     font-weight: bolder;
     font-size: 1.5rem;
  }
  
  .flex-80 p {
    color: grey;
  }
  .why-chooseimg {
    width : 100%;
  }
  
  .why-choose-text {
    padding: 15px;
    text-align: center;
  }
  
  .why-choose-text p {
    color: grey;
    font-size: 19px;
     line-height: 1.6;
  }
  
  .why-choose-text h3 {
      margin: 30px 3px;
      font-size: 2.1rem;
  }
  
  
  .why-choose-text button {
    display: flex;
    justify-self: center;
    align-items: center;
    margin: 25px 20px 5px 35%;
    background-color: white;
    border: 2px solid #061a79;
    padding: 20px 30px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 400;
    color: #280071;
  } 
  

.bttn{
  display: flex;
    justify-self: center;
    align-items: center;
    margin: 25px 20px 5px 35%;
    background-color: white;
    border: 2px solid #061a79;
    padding: 20px 30px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 400;
    color: #280071;
  } 

  .skewy-div:hover {
    transform: skewy(-2deg);
  }
  



    .flex-20  img {
         width :80%;
    }



    .cardinal-values {
      background-color: #fbfbfb;
      padding: 0px;
    }
    
    .cardinalgrid-container {
      display: grid;
      grid-template-columns: 40% 60%;
      gap: 0;
    }
    
    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .image {
     width: 100%;
      height: auto;
    }
    
    .text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 20px;
    }
    
    .text-container h6 {
      font-size: 34px;
    }
    
    .text-container p {
      font-size: 22px;
    }
    
   

    .startbig {
      background-color: #042e44;
      background-image: url('images/trans.png');
      background-size: cover;
      background-position: center;
      color: white !important;
    }

    .startbig h5 {
      color: #fff;
      font-size: 24px;
    }
   


    .startbig button {
      background-color: white !important;
      color: #bb0b0b;
    }


    .image-gallery {
      padding: 20px;
    }
    
    .imggrid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
    
    .card {
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      transition: background-color 0.3s;
      border: 2px solid #fbfbfb;
    }
    




 
    .card h2{
      font-weight: 700;
      font-size: 25px;
    }


    .card:hover {
      background-color: #32a4a8;
      color:white;
    }
    
    .card-image {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
    
    .card-details {
      margin-top: 10px;
      text-align: center;
    }
    
    .card-details h6 {
      font-size: 18px;
    }
    
    .card-details p {
      font-size: 14px;
    }
    

    .whowearecontainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1200px; /* Adjust as needed */
      margin: 0 auto;
    }
    
    .whoweareitem {
      width: calc(50% - 2px); /* Adjust margin as needed */
      margin-bottom: 3px;
      padding: 10px;
      border: 1px solid #ccc;
      box-sizing: border-box;
      text-align: center;
    }

    .whoweareitem p {
      font-size: 16px;
    }

    .whoweareitem h1 {
      font-size: 46px;
      font-weight: 800;
      color: #061979;
    }
    

    .whoweareitem h5 {
      font-size: 26px;
      font-weight: 800;
      
    }

    .newfooter {
      background-color: rgba(0, 0, 0, 0.925);
      color: #ccc !important;
      padding: 20px;
    }
    
    .footergrid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 80px;
    }
    
    .logo-section h2 {
      font-size:25px;
      margin-top: 20px;
      font-weight: 900;
    }

    .logo-section p {
      font-size : 20px;
    }
    
    .address-section {
      display: flex;
      flex-direction: column;
    }
    
    .address-section p {
      margin-bottom: 5px;
    }
    
    .address-section a {
      text-decoration: 5px underline #32a4a8;
    
    }

    .links-section .red-bottom {
        text-decoration: 5px underline #32a4a8 !important;
    }
    .icon {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }

    .icon i {
      color:#0049af ;
      font-size: 34px;
      margin: 7px;
    }


    
    .icon p {
      color:rgba(255, 255, 255, 0.74) ;
      font-size: 19px;
      margin: 7px;
    }
    
    
    .icon svg {
      margin-right: 5px;
    }
    
    .links-section h3 {
       margin:  20px 2px;
    }

    .address-section h3 {
      margin:  20px 2px;
   }
  
    .logo-section h2 {
      margin-top : 25px ;
    }

   .footerbg {
    background-color: black !important;
   }
/* 
    .links-section span {
      background-color: #bb0b0b;
      padding: 0 20px;
      width: 5px;
      height: 3px;
      border-radius: 2px;
    } */




    .links-section ul {
      list-style-type: none;
      padding: 0;
      font-size: 19px;
    }
    
    .links-section ul li {
      margin-bottom: 5px;
    }
    
    .formcontainer {
      padding: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    
    .card {
      background-color: #f0f0f0;
      padding: 20px;
    }
    
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    
    .form-group {
      display: flex;
      flex-direction: column;
    }
    
    label {
      font-weight: bold;
    }
    
    input,
    textarea {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    button {
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 10px 15px;
      border-radius: 4px;
      cursor: pointer;
    }

    .address-grid {
      padding: 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
    
    .address-box {
      border: 1px solid #ccc;
      padding: 20px;
      border-radius: 8px;
    }
    
     .address-box h2 {
      margin-bottom: 5px;
    }
    
      .address-box h4,
    p {
      margin: 0;
    }
    


    /* TermsAndConditions.css */

.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accordion {
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

.accordion-content {
  padding: 10px;
  display: none;
}

.accordion.expanded .accordion-content {
  display: block;
}

.accordion-arrow {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.accordion.expanded .accordion-arrow {
  transform: rotate(180deg);
}



.terms-heading {
  text-align: center;
}

.terms-heading h1 {
  color: blue;
  font-size: 2rem; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin for h1 */
  padding: 20px; /* Add padding for better visibility */
}


.termsHeading {
  font-size: 1.5rem;
  margin-top: 20px;
  font-style: italic;
  font-weight: 700;
  text-align: left !important;
}




/* Existing styles for .container, h1, and button remain the same */

.servicecontainer h1 {
  color:#00344c ;
  text-align: center;
  font-weight: 900;
  padding: 40px;
}   

.servicecontainer i {
  color: #007bff ;
 
}   

.servicecontainer button {
  display: flex;
  margin: 1px auto;
  border-radius: 10px;
  border-bottom: 6px solid #032e42;
  color:white ;
  text-align: center;
  font-weight: 900;
  padding: 10px 20px;
}   


.servicecontainer button:hover {

  border-bottom: 4px solid #032e42;
  font-size: 15px;
 
}   

.servicecontainer {
  color:#00344c ;
}

.services-grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-bottom: 10px;

}

.servicesgrid {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.servicesgrid h2 {
  font-size: 1.5rem;
  font-weight: 900;
}

.servicesgrid p {
  font-size: 1rem;
}

.servicesgrid ul {
  list-style: none;
  padding: 0;
}

.servicesgrid  ul li {
  font-size: 1.2rem;
  padding-top: 10px;
}


/* 
design for partnership */

.partnercontainer h1 {
  color:#00344c ;
  text-align: center;
  font-weight: 900;
  padding: 40px;
}   

.partnercontainer i {
  color: #007bff ;
 
}   

.partnercontainer button {
  display: flex;
  margin: 1px auto;
  border-radius: 10px;
  border-bottom: 6px solid #032e42;
  color:white ;
  text-align: center;
  font-weight: 900;
  padding: 10px 20px;
}   


.partnercontainer button:hover {

  border-bottom: 4px solid #032e42;
  font-size: 15px;
 
}   

.partnercontainer {
  color:#00344c ;
}

.partners-grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-bottom: 50px;

}

.partnergrid {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.partnergrid h2 {
  font-size: 1.5rem;
  font-weight: 900;
}

.partnergrid p {
  font-size: 1rem;
}

.partnergrid ul {
  list-style: none;
  padding: 0;
}

.partnergrid  ul li {
  font-size: 1.2rem;
  padding-top: 10px;
}
 /* end of partnership */


/* Existing styles for .container and h3 remain the same */

.steps-grid {
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.step {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.step h2 {
  font-size: 3.5rem;
  font-weight: 900;
  color: #007bff;
}

.step h4 {
  font-size: 1.2rem;
  margin-top: 10px;
}

.step p {
  font-size: 1rem;
  font-weight: 700;
}

/* Responsive Design */



@media screen and (max-width: 765px) {
  .step {
    padding: 10px;
  }
  .steps-grid {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
  }
}

@media screen and (max-width: 500px) {
  .steps-grid {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
  }
  .step h2 {
    font-size: 1.2rem;
  }
  
  .step h4 {
    font-size: 1rem;
  }
  
  .step p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 320px) {
  .step h2 {
    font-size: 1rem;
  }
  
  .step h4 {
    font-size: 0.8rem;
  }
  
  .step p {
    font-size: 0.8rem;
  }
}



/* Responsive Design */

@media screen and (max-width: 1200px) {
  .servicecontainer {
    padding: 10px;
  }
  
  .servicesgrid {
    padding: 10px;
  }


  .partnercontainer {
    padding: 10px;
  }
  
  .partnergrid {
    padding: 10px;
  }
}

@media screen and (max-width: 765px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .servicesgrid  ul li {
    font-size: 0.8rem;
    padding-top: 10px;
  }

  .partners-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .partnergrid  ul li {
    font-size: 0.8rem;
    padding-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
  
  .servicesgrid {
    padding: 10px;
  }

  .partners-grid {
    grid-template-columns: 1fr;
  }
  
  .partnergrid {
    padding: 10px;
  }
  
}

@media screen and (max-width: 320px) {
    
  .servicecontainer h1 {
    font-size: 2rem;
  }
  
   
  .servicesgrid h2 {
    font-size: 1.2rem;
  }
  
   
  .servicesgrid p {
    font-size: 0.8rem;
  }
  
  .servicesgrid li {
    font-size: 0.7rem;
  }

  .partnercontainer h1 {
    font-size: 2rem;
  }
  
   
  .partnergrid h2 {
    font-size: 1.2rem;
  }
  
   
  .partnergrid p {
    font-size: 0.8rem;
  }
  
  .partnergrid li {
    font-size: 0.7rem;
  }
}





/* Existing styles for .container remain the same */

/* Existing styles for .container remain the same */

/* Existing styles for .container remain the same */





/* Existing styles for .container remain the same */

.imagelogo-grid {
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 80px;
}

.image-grid {
  width: 80%;
  overflow: hidden;
  border-radius: 8px;
}

.image-grid img {
  width: 100%;
  height: auto;
  display: block;
}



@media screen and (max-width: 1200px) {
 
  
  .imagelogo-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 765px) {
  .imagelogo-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .imagelogo-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 320px) {
  .imagelogo-grid {
    grid-template-columns: 1fr;
  }
}


/* Existing styles for .container remain the same */

.testimonial-grid {
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 20px;
}

.testigrid {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.imag-container {
  flex: 10%;
  max-width: 20%;
  display: flex;
  align-self: flex-start;
  justify-content: center;
}

.imag-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.content-container {
  flex: 80%;
}

.content-container h6 {
  color: blue;
  font-size: 0.9rem;
  margin: 0;
}

.content-container p {
  font-size: 1rem;
  margin: 0;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.video-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%; /* Maintain aspect ratio (16:9) */
}

video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}


/* Responsive Design */

@media screen and (max-width: 1200px) {
  .container {
    padding: 10px;
  }

  .testigrid {
    flex-direction: row;
    gap: 5px;
  }

  
  .imag-container {
    margin-bottom: 5px;
  }

  .testimonial-grid {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;

    gap: 20px;
  }
}


.rounded {
  border-radius: 100%;
}

@media screen and (max-width: 765px) {

  .testimonial-grid {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;

    gap: 20px;
  }
  
  .testigrid h4 {
    font-size: 1rem;
  }

  .testigrid p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 500px) {
  .testigrid {
    padding: 5px;
  }
  
  .image-container img {
    border-radius: 4px;
  }
}


@media screen and (max-width: 320px) {
  .testigrid {
    gap: 5px;
  }

  .testigrid h4 {
    font-size: 0.9rem;
  }

  .testigrid p {
    font-size: 0.8rem;
  }
}








@media (max-width: 768px) {
  .whoweareitem {
    width: 100%;
  }
}
/* Media queries for responsiveness */
@media screen and (max-width: 767px) {
  /* For small screens (up to 767px) */
  .terms-heading h1 {
    font-size: 20px;
  }
}

/* Add more media queries as needed for other screen sizes */

@media (max-width: 767px) {
  .accordion {
    max-width: 90%;
  }
}

    @media screen and (max-width: 768px) {
      .address-grid {
        grid-template-columns: 1fr;
      }
    }
    
    
    @media screen and (max-width: 768px) {
      .formcontainer {
        grid-template-columns: 1fr;
      }
    }
    

    
  @media (max-width: 1275px) {

   
    .grid-child-50percent h3 {
      font-size: 2rem;
      letter-spacing: 1.8px;
      padding-right: 10px;
      color: #280071;
 
 }
 .newshowcase-content {
  text-align: left;
  background-color: inherit;
  margin-top: 15%;
  margin-left: 70px;
  
}
}

@media (max-width: 1232px) {
  .flex-40-60 {
   
    display: grid;
    grid-template-columns: 50% 50% ;
    gap:30px;
    padding: 35px 13px;
  
  }
  
  .flex-40  h5{
      margin: 20px 30px;
      font-size: 10px;
      color: #06197975;
  }
  
  .flex-40  h2{
    margin: 30px 30px;
    font-size: 2rem;
    color: #061979;
  }
  
  .flex-40  h4{
    margin: 30px 30px;
    font-size: 15px;
    color: #0f0f0f;
  } 
  .newshowcase-content {
    text-align: left;
    background-color: inherit;
    margin-top: 15%;
    margin-left: 10px;
    
  }
  .newfooter {
    background-color: #032e42;
    color: #ffffffc7;
    padding: 20px;
  }
  
  .footergrid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  
  .logo-section h2 {
    font-size: 24px;
    margin-top: 20px;
  }
  
  .address-section {
    display: flex;
    flex-direction: column;
  }
  
  .address-section p {
    margin-bottom: 5px;
  }
  
  .icon {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .icon i {
    color:#bb0b0b ;
    font-size: 34px;
    margin: 7px;
  }


  
  .icon p {
    color:rgba(255, 255, 255, 0.74) ;
    font-size: 17px;
    margin: 7px;
  }
  
  
  .icon svg {
    margin-right: 5px;
  }
  
  .links-section h3 {
     margin:  20px 2px;
  }

  .address-section h3 {
    margin:  20px 2px;
 }

  .logo-section h2 {
    margin-top : 25px ;
  }

 .footerbg {
  background-color: #00344c !important;
 }
/* 
  .links-section span {
    background-color: #bb0b0b;
    padding: 0 20px;
    width: 5px;
    height: 3px;
    border-radius: 2px;
  } */

  .links-section ul {
    list-style-type: none;
    padding: 0;
    font-size: 18px;
  }
  
  .links-section ul li {
    margin-bottom: 5px;
  }
  
}
    

@media (max-width: 1078px) { 

  .newnavbar-middle {
    display: flex;
    gap: 15px;
    flex: 1;
    font-size: 12px;
    font-weight: 800;
    transition: all 0.3s ease;
    overflow: hidden;
    color: black;
  }
  .grid-child-50percent h3 {
    font-size: 1.7rem;
    letter-spacing: 1px;
    padding-right: 10px;
    color: #280071;

} 
.newfooter {
  background-color: #032e42;
  color: #ffffffc7;
  padding: 10px;
}

.footergrid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.logo-section h2 {
  font-size: 20px;
  margin-top: 15px;
}

.address-section {
  display: flex;
  flex-direction: column;
}

.address-section p {
  margin-bottom: 5px;
}

.icon {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.icon i {
  color:#bb0b0b ;
  font-size: 24px;
  margin: 7px;
}



.footergrid-container p {
  color:rgba(255, 255, 255, 0.74) ;
  font-size: 12px;
  margin: 0px;
}


.icon svg {
  margin-right: 5px;
}

.links-section h3 {
   margin:  20px 2px;
}

.address-section h3 {
  margin:  20px 2px;
}

.logo-section h2 {
  margin-top : 25px ;
}

.footerbg {
background-color: #00344c !important;
}
/* 
.links-section span {
  background-color: #bb0b0b;
  padding: 0 20px;
  width: 5px;
  height: 3px;
  border-radius: 2px;
} */

.links-section ul {
  list-style-type: none;
  padding: 0;
  font-size: 16px;
}

.links-section ul li {
  margin-bottom: 5px;
}

.servicesgrid  ul li {
  font-size: 0.7rem;
  padding-top: 10px;
}

.servicesgrid  p {
  font-size: 0.8rem;

}

.partnergrid  ul li {
  font-size: 0.7rem;
  padding-top: 10px;
}

.partnergrid  p {
  font-size: 0.8rem;

}
.steps-grid {
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
}
@media (max-width: 920px) { 
  .mbtn {
     font-size : 12px;
     padding: 5px 10px;
  }
}


@media (max-width: 804px) {
  .grid-parent-two {
    margin-left: 2px;
    display: grid;
    grid-template-columns:  repeat(1, 1fr);
    gap:80px;
    text-align: center;
  }
  .grid-child-50percent h3 {
       font-size: 2.5rem;
       letter-spacing: 1.8px;
       padding-right: 10px;
       color: #280071;
  
  }
  
  .grid-child-50percent  span {
    color: blue;
  }
  
  .grid-child-50percent  p {
    margin-top: 30px;
    font-size: 1rem;
    color:gray;
  
  }
  .straight-grid {
    margin-top: 30px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 30px;
  }
  .straight-grid h4 {
    color:#280071;
  }

  .red-button {
    background-color: #bb0b0b;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 70px ;
    font-size: 16px;

  }
  
}

  @media only screen and (max-width: 900px) {
    .newnavbar-middle, .newnavbar-right {
      display: none;
    }
  
    .hamburger {
      display: flex;
    }
  
    .newnavbar-container {
      justify-content: space-between;
      padding: 10px;
    }
  
    .newnavbar-middle.open, .newnavbar-right.open {
      display: flex;
      flex-direction: column;
      padding-top: 0px;
    
    }

    .logo.open {
     
      width: 100%;
      height:10%;
      padding-right: 1px;
      
    }
    .newshowcase-content h1 {
      font-size: 28px;
      text-align: left;
      padding: 6px;
    }
  
    .newshowcase-content h3 {
      font-size: 20px;
      text-align: left;
      padding: 0px;
    }


    
    .newshowcase-content {
      text-align:left;
      background-color: inherit;
      margin-top: 15%;
    
      margin-bottom: 15%;
      margin-left: 47px;
      
    }
    .newshowcase {
      background-image: url('images/dubai-transformed.png');
      background-size:cover;
      background-repeat: no-repeat;
      background-position: top left;
      height: auto;
      max-width: 100vw;
      display: flex;
      align-items: flex-start;
      justify-content:flex-start;
      color: white;
     
    }
    .footergrid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 50px;
    }
  }
  
  /* Responsive Styles */
  @media only screen and (max-width: 767px) {
    .pre-navbar {
      flex-direction: column;
      padding: 10px;
    }
  
    .left-section {
      margin-bottom: 10px;
    }
  
    .right-section {
      margin-left: 0;
      justify-content: flex-start;
    }
  }
  

  @media only screen and (max-width: 715px) {

    
    .newshowcase-content h1 {
      font-size: 20px;
      text-align: left;
      padding: 6px;
    }
  
    .newshowcase-content h3 {
      font-size: 15px;
      text-align: left;
      padding: 0px;
    }


    
    .newshowcase-content {
      text-align:left;
      background-color: inherit;
      margin-top: 15%;
    
      margin-bottom: 15%;
      margin-left: 67px;
      
    }
    .logo {
     
      width: 80%;
      height:10%;
      padding-right: 1px;
      
    }

    
  }

  @media only screen and (max-width: 600px) {
    
    .newshowcase-content h1 {
      font-size: 20px;
      text-align: left;
      padding: 6px;
    }
  
    .newshowcase-content h3 {
      font-size: 15px;
      text-align: left;
      padding: 0px;
    }


    
    .newshowcase-content {
      text-align:left;
      background-color: inherit;
      margin-top: 25%;
    
      margin-bottom: 25%;
      margin-left: 47px;
      
    }
    .pre-navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: red;
      color: white;
      padding: 10px 20px;
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 600px) {
    .newgrid-container {
      grid-template-columns: 100%;
    }
  
    .newgrid-item {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    .cardinalgrid-container {
      grid-template-columns: 100%;
    }
  
    .image-container {
      justify-content: flex-start;
      padding: 0 20px;
    }
  
    .text-container {
      align-items: center;
      text-align: center;
    }
  
    .text-container h6 {
      font-size: 20px;
    }
  
    .text-container h4 {
      font-size: 28px;
    }
  }
  

  @media only screen and (max-width: 600px) {
    .imggrid-container {
      grid-template-columns: 1fr;
    }
  }
  
  
  @media only screen and (max-width: 600px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
  
 
  @media only screen and (max-width: 600px) {
    .footergrid-container {
      grid-template-columns: 1fr;
    }
  }


  @media (max-width: 588px) {  
    .grid-child-50percent h3 {
      font-size: 1.5rem;
      letter-spacing: 1.8px;
      padding-right: 10px;
      color: #280071;
  
  }
  }  

  @media only screen and (max-width: 531px) {
    .newshowcase-content h1 {
      font-size: 28px;
      text-align: left;
      padding: 6px;
    }
  
    .newshowcase-content h3 {
      font-size: 25px;
      text-align: left;
      padding: 0px;
    }


    
    .newshowcase-content {
      text-align:left;
      background-color: inherit;
      margin-top: 25%;
    
      margin-bottom: 25%;
      margin-left: 47px;
      
    }
    .pre-navbar {
      display: none;
      justify-content: center;
      align-items: center;
      background-color: #00344c;
      color: white;
      padding: 10px 5px;
      font-size: 16px;
      text-align: center;
    }
    .left-section {
      display: flex;
      flex-direction: column;
    }
  
    .left-section  span  {
      padding-left: 5px;
      font-weight: 700;
    }
  }



  

  
  @media only screen and (max-width: 397px) {
    .pre-navbar {
      display: none;
      justify-content: center;
      align-items: center;
      background-color: red;
      color: white;
      padding: 5px 2px;
      font-size: 16px;
    }
    .newshowcase-content h1 {
      font-size: 18px;
      text-align: left;
      padding: 2px;
    }
  
    .newshowcase-content h3 {
      font-size: 15px;
      text-align: left;
      padding: 2px;
    }


    
    .newshowcase-content {
      text-align:left;
      background-color: inherit;
      margin-top: 15%;
    
      margin-bottom: 30%;
      margin-left: 47px;
      
    }
    .logo {
      width: 100%;
      height:10%;
      padding-right: 150px;
      
    }
    .newgrid-item h5 {
      font-size: 12px;
      display: block;
      text-align: left;
      margin-top : 20px;
      color: white;
      font-size: 16px;
    }
    
    .newgrid-item h2 {
      font-size: 20px;
      text-align: left;
    }
    .logo {
     
      width: 80%;
      height:10%;
      padding-right: 1px;
      
    }
    .newnavbar-middle.open {
      font-size: 6px;
      gap: 5px;
    }
    
    
    .newnavbar-right .btn {
      background-color: red;
      color: white;
      border: none;
      padding: 4px 6px;
      border-radius: 4px;
      font-weight: 400;
      font-size: 6px;
    }
    
    .mbtn {
      font-size : 10px;
      padding: 2px 5px;
   }
  }
  @media only screen and (max-width: 320px) {
    .logo-section h6 {
      font-size: 16px;
    }
  
    .address-section p,
    .links-section ul li {
      font-size: 14px;
    }

    .pre-navbar {
      display: none;
      justify-content: center;
      align-items: center;
      background-color: red;
      color: white;
      padding: 5px 2px;
      font-size: 16px;
    }
    .left-section {
      display: flex;
      flex-direction: column;
    }
  
    .left-section  span  {
      padding-left: 5px;
      font-weight: 700;
    }
    .newnavbar-right .btn {
      background-color: red;
      color: white;
      border: none;
      padding: 4px 6px;
      border-radius: 4px;
      font-weight: 400;
      font-size: 6px;
      width: 100%;
    }
    .btn {
      background-color: red;
      color: white;
      border: none;
      padding: 3px 6px;
      border-radius: 4px;
      font-weight: 800;
      width: 50%;
    }
    .logo {
     
      width: 60%;
      height:10%;
      padding-right: 1px;
      
    }


    .newshowcase-content h1 {
      font-size: 18px;
      text-align: left;
      padding: 2px;
    }
  
    .newshowcase-content h3 {
      font-size: 15px;
      text-align: left;
      padding: 2px;
    }


    
    .newshowcase-content {
      text-align:left;
      background-color: inherit;
      margin-top: 15%;
    
      margin-bottom: 30%;
      margin-left: 27px;
      
    }
    
  }
  